import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { H1, H3 } from '@styles/Typography';

import { RightArrowCircleIcon } from '@components/Icons';
import { Wrapper, Section } from '@components/Layout/styles';

import SEO from '@components/seo';
import Breadcrumb from '@components/Breadcrumb';
import Button, { ButtonGroup } from '@components/Button';
import Sidebar from '@components/Sidebars/Main';

import * as S from './_styles';

export const pageQuery = graphql`
	query FaqBySlug($slug: String!) {
		questions: allCosmicjsFaq(filter: { slug: { eq: $slug } }) {
			nodes {
				slug
				metadata {
					question
					metas {
						title
						description
						cover {
							imgix_url
						}
					}
					answer
					related {
						title
						slug
					}
				}
			}
		}
	}
`;

const FaqTemplate = ({ data }) => {
	const { question, answer, related, metas } = data.questions.nodes[0].metadata;
	const { slug } = data.questions.nodes[0];
	return (
		<>
			<SEO
				title={`${metas?.title || question}`}
				description={`${
					metas?.description ||
					`${answer.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 156)}...`
				}`}
				cover={metas?.cover.imgix_url}
				metaType="article"
			/>

			<Wrapper>
				<Breadcrumb
					crumbs={[
						{ title: 'Perguntas frequentes', slug: 'perguntas-frequentes' },
						{ title: question, slug: `perguntas-frequentes/${slug}` },
					]}
				/>
			</Wrapper>

			<Section>
				<S.Wrapper>
					<S.MainContent>
						<H1>{question}</H1>

						<div dangerouslySetInnerHTML={{ __html: answer }} />

						<S.Helpful>
							<H3>Esta pergunta foi útil?</H3>

							<ButtonGroup>
								<Button type="button" outlined data-id="button-helpful-yes">
									Sim
								</Button>
								<Button type="button" outlined data-id="button-helpful-no">
									Não
								</Button>
							</ButtonGroup>
						</S.Helpful>

						<S.CTA />
						{related && (
							<S.Related>
								<H3>Perguntas Relacionadas</H3>
								{related.map((item, index) => (
									<S.Link key={index} to={`/perguntas-frequentes/${item.slug}`}>
										<RightArrowCircleIcon size={24} />
										<span>{item.title}</span>
									</S.Link>
								))}
							</S.Related>
						)}
					</S.MainContent>

					<Sidebar />
				</S.Wrapper>
			</Section>
		</>
	);
};

FaqTemplate.propTypes = {
	data: PropTypes.shape({
		questions: PropTypes.shape({
			nodes: PropTypes.arrayOf(PropTypes.object),
		}),
	}).isRequired,
};

export default FaqTemplate;
