import styled from 'styled-components';
import { Link as GLink } from 'gatsby';

import { colors, media, spacing, toRem } from '@styles/Theme';
import { Wrapper as LWrapper } from '@components/Layout/styles';

import CCTA from '@components/CTAS/Appointment';

export const Wrapper = styled(LWrapper)`
	@media (${media.tablet}) {
		padding-left: ${spacing(8)};
	}

	@media (${media.desktop}) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding-left: ${spacing(15)};
	}
`;

export const MainContent = styled.div`
	h1 {
		margin-bottom: ${spacing(4)};
		line-height: 1.2;
	}

	@media (${media.desktop}) {
		flex: 1;
		margin-right: ${spacing(4)};
	}
`;

export const Helpful = styled.div`
	margin: ${spacing(5, 'auto')};
	padding-top: ${spacing(5)};
	text-align: center;
	border-top: ${toRem(2)} dashed ${colors.lightGrey};

	button {
		max-width: fit-content;
	}
`;
export const CTA = styled(CCTA)`
	margin: ${spacing(5, 'auto')};
`;

export const Link = styled(GLink)`
	position: relative;
	display: flex;
	align-items: center;
	height: ${spacing(9)};
	padding: ${spacing(0, 6, 0, 3)};
	overflow: hidden;
	color: ${colors.darkGrey};
	font-weight: bold;
	font-size: ${toRem(18)};
	text-decoration: none;
	background-color: #fff;
	border-radius: ${spacing(1)};
	cursor: pointer;
	transition: all 0.2s linear;
	user-select: none;

	&:not(:last-child) {
		margin-bottom: ${spacing(1)};

		@media (${media.tablet}) {
			margin-bottom: ${spacing(3)};
		}
	}

	&::before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: ${colors.gradient(150)};
		opacity: 0;
		transition: opacity 0.1s linear;
		content: '';
	}

	svg {
		position: absolute;
		top: ${spacing(3)};
		right: ${spacing(2)};
		flex-shrink: 0;
		color: ${colors.lightGrey};
	}

	span {
		position: relative;
	}

	&:hover {
		color: #fff;

		&::before {
			opacity: 1;
		}

		svg {
			color: #fff;
		}
	}
`;

export const Related = styled.div`
	h3 {
		padding-bottom: ${spacing(3)};
		text-align: center;
		border-bottom: ${toRem(1)} solid ${colors.lightGrey};
	}
`;
